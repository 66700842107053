import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  data: any;
  ErrMessage: any;
  email: any;
  password: any;
  exampleCheck = false;
  isErrMessage = false;
  constructor(
    public global: GlobalService,
    private router: Router
  ) { }

  ngOnInit() {
    this.email = localStorage.getItem('email') ? localStorage.getItem('email') : '';
    this.password = localStorage.getItem('password') ? localStorage.getItem('password') : '';
  }

  login() {
    const data = {
      email: this.email,
      password: this.password
    };
    const url = this.global.basePath + 'admin/login';
    this.global.PostRequest(url, data).subscribe(
      (res: any) => {
        this.data = res[0].json;
        console.log('TCL: ChatService -> constructor -> res', this.data);
        localStorage.setItem('token', this.data.token.access_token);
        if (this.data.user) {
          localStorage.setItem('userId', this.data.user._id);
          if (this.exampleCheck) {
            localStorage.setItem('email', this.email);
            localStorage.setItem('password', this.password);
          } else {
            localStorage.removeItem('email');
            localStorage.removeItem('password');
          }
          this.router.navigate(['/dashboard']);
        } else {
          this.ErrMessage = 'Enter correct credentials.';
          this.isErrMessage = true;
          setTimeout(() => {
            this.isErrMessage = false;
          }, 1000);
        }
      },
      (err: any) => {
        this.ErrMessage = 'Enter correct credentials.';
        this.isErrMessage = true;
        setTimeout(() => {
          this.isErrMessage = false;
        }, 3000);
      }
    );
  }

}
