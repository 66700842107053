<div class="h-100 bg-plum-plate bg-animation">
 
    <div class="d-flex h-100 justify-content-center align-items-center">
      <div class="mx-auto app-login-box col-md-8">
        <div class="app-logo-inverse mx-auto mb-3"></div>
        <div class="modal-dialog w-100 mx-auto">
          <div class="modal-content">
            <div class="modal-body">
              <div class="h5 modal-title text-center">
                <img class="login-logo" src="assets/icons/Logo.JPG">
                <h4 class="mt-2" style="margin-bottom:20px">
                  <div>Welcome back,</div>
                  <span>Please sign in to your account below.</span>
                </h4>
              </div>
              <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_"
                class="form-group">
                <div><input [(ngModel)]='email' type="email" placeholder="Enter email..." required="required"
                    aria-required="true" class="form-control" aria-describedby="exampleInputGroup1__BV_description_">
                  <small tabindex="-1" id="exampleInputGroup1__BV_description_" class="form-text text-muted">We'll never
                    share your email with anyone else.
                  </small>
                </div>
              </div>
              <div id="exampleInputGroup2" role="group" class="form-group">
                <div><input [(ngModel)]='password' type="password" placeholder="Enter password..." required="required"
                    aria-required="true" class="form-control"></div>
              </div>
              <p *ngIf='isErrMessage' style="color: red;">{{ErrMessage}}</p>
              <div class="custom-control custom-checkbox"><input id="exampleCheck" type="checkbox" [(ngModel)]="exampleCheck"
                  autocomplete="off" class="custom-control-input" value="true"><label for="exampleCheck"
                  class="custom-control-label">
                  Keep me logged in
                </label></div>
              <div class="divider"></div>
              <!-- <h6 class="mb-0">
                No account?
                <a [routerLink]="pages/register-boxed" class="text-primary">Sign up now</a></h6></div> -->
              <div class="modal-footer clearfix" style="background: none;border-top: none; justify-content: center;">
                <!-- <div class="float-left"><a [routerLink]="" class="btn-lg btn btn-link">Recover
                Password</a></div> -->
                <div class="float-right">
                  <button type="button" class="btn btn-primary btn-lg" (click)='login()'>Login</button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="text-center text-white opacity-8 mt-3">
          Copyright © Blabee 2019
        </div> -->
        </div>
      </div>
    </div>