import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '',
        title: 'Dashboard',
        icon: '',
        class: 'nav-small-cap',
        label: '',
        labelClass: '',
        extralink: true,
        submenu: []
    },
    {
        path: '/dashboard',
        title: 'Dashboard',
        icon: 'mdi mdi-gauge',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '',
        title: 'Registration Types',
        icon: '',
        class: 'nav-small-cap',
        label: '',
        labelClass: '',
        extralink: true,
        submenu: []
    },
    // {
    //     path: '/dashboard/component/union',
    //     title: 'Union Member',
    //     icon: 'mdi mdi-message-bulleted',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    {
        path: '/dashboard/component/archer',
        title: 'Archer',
        icon: 'mdi mdi-account',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/technicalofficial',
        title: 'Technical official',
        icon: 'mdi mdi-account',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '/dashboard/component/coach',
        title: 'Coach',
        icon: 'mdi mdi-account',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/teamofficial',
        title: 'Team Officials',
        icon: 'mdi mdi-account',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/fan',
        title: 'Fan',
        icon: 'mdi mdi-account',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/payments',
        title: 'Payments',
        icon: 'mdi mdi-account',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '/dashboard/component/event',
        title: 'Event',
        icon: 'mdi mdi-account-circle',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '/dashboard/component/news',
        title: 'News',
        icon: 'mdi mdi-newspaper',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '/dashboard/component/gallery',
        title: 'Gallery',
        icon: 'mdi mdi-file-image',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '/dashboard/component/awards',
        title: 'Awards',
        icon: 'mdi mdi-trophy-variant',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '/dashboard/component/circular',
        title: 'Upload Data',
        icon: 'mdi mdi-newspaper',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/result',
        title: 'Result',
        icon: 'mdi mdi-newspaper',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/record',
        title: 'Record',
        icon: 'mdi mdi-newspaper',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '/dashboard/component/banner',
        title: 'Banner',
        icon: 'mdi mdi-newspaper',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '/dashboard/component/addJudges',
        title: 'Add Judge',
        icon: 'mdi mdi-account',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/calendar',
        title: 'Calendar',
        icon: 'mdi mdi-calendar',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/gcArchers',
        title: 'Gc Archers',
        icon: 'mdi mdi-account',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/logo',
        title: 'Logo',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },


    {
        path: '/dashboard/component/compliance',
        title: 'Compliance',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '/dashboard/component/committees',
        title: 'Committees',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '/dashboard/component/executive-committees',
        title: 'Executive Committees',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/about-us',
        title: 'About us',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/rules',
        title: 'Rules',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/history',
        title: 'History',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/footer',
        title: 'Footer',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/rti',
        title: 'RTI',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/sexualHarrasment',
        title: 'Sexual Harassment',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard/component/achievements',
        title: 'Achievements',
        icon: 'mdi mdi-trophy-variant',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    // {
    //     path: '/component/poptool',
    //     title: 'Popover & Tooltip',
    //     icon: 'mdi mdi-image-filter-vintage',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/component/progressbar',
    //     title: 'Progressbar',
    //     icon: 'mdi mdi-poll',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/component/rating',
    //     title: 'Ratings',
    //     icon: 'mdi mdi-bandcamp',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/component/tabs',
    //     title: 'Tabs',
    //     icon: 'mdi mdi-sort-variant',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/component/timepicker',
    //     title: 'Timepicker',
    //     icon: 'mdi mdi-calendar-clock',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/component/buttons',
    //     title: 'Button',
    //     icon: 'mdi mdi-toggle-switch',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/component/cards',
    //     title: 'Card',
    //     icon: 'mdi mdi-blur-radial',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/component/toast',
    //     title: 'Toast',
    //     icon: 'mdi mdi-message-reply-text',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // }
    {
        path: '/dashboard/component/tournament',
        title: 'Tournament',
        icon: 'mdi mdi-account-circle',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    }
];
